<template>
  <v-container class="not-extended">
    <v-row>
      <v-col
        v-for="item in items"
        :key="item.ref"
        cols="12"
        :sm="item.type == 'box' ? 6 : 12"
        :md="item.type == 'box' ? 4 : 12"
        :xl="item.type == 'box' ? 3 : 12"
      >
        <!-- CAJA DE CONTENIDO -->
        <v-hover v-slot="{ hover }" v-if="item.type == 'box'">
          <v-card @click="redirigir(item.path)">
            <v-card-title primary-title class="justify-center">
              <v-icon x-large :color="'primary darken-4'">
                mdi-{{ item.icons.index }}
              </v-icon>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              {{ item.text }}
            </v-card-text>
            <v-card-actions
              :class="['overflow-hidden', hover ? 'info' : 'primary']"
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="white--text font-weight-bold">{{
                    item.title
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item class="justify-end">
                <v-icon class="mr-1" color="white"> mdi-open-in-new </v-icon>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-hover>
        <!-- DIVISOR -->
        <v-divider v-else-if="item.type == 'divider'"></v-divider>
        <h3 class="text-h5" v-else-if="item.type == 'title'">
          {{ item.title }}
        </h3>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { pages } from "@/router/data/index.js";
export default {
  name: "Home",
  created() {
    this.$store.commit("setPage", { page: this.page });
  },
  data() {
    return {
      page: "01",
      isActive: false,
    };
  },
  computed: {
    items() {
      return pages;
    },
  },
  methods: {
    redirigir(path) {
      this.$router.push({ name: path });
    },
  },
};
</script>
